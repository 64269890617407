@import url('reset.css');

@font-face {
    font-family: 'Crimson Pro';
    font-style: regular;
    src: url('./fonts/CrimsonPro-Roman-VF-2.woff2') format('woff2-variations');;
}
@font-face {
    font-family: 'Crimson Pro';
    font-style: italic;
    src: url('./fonts/CrimsonPro-Italic-VF-2.woff2') format('woff2-variations');;
}

html {
    background-color: #fffff8;
    color: #333;

    font-family: 'Crimson Pro';
    font-size: 21px;
    line-height: 30px;
    height: 100%;
    hanging-punctuation: first allow-end last;
}

body {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: minmax(100px, auto);
    min-height: 100%;
    
    > nav {
        grid-column: 1 / 4;
        grid-row: 1;
        background-color: #ecece7;
        position: relative;
        
        #logo {
            display: block;
            padding: 0.5em;
            &:hover {
                background-color: #dcdcd8;
            }
        }
        
        a:link, a:active, a:hover, a:visited {
            border: none;
            color: inherit;
        }

        ul.main-nav li {
            list-type-type: none;
            margin: 0;
        }
        ul.main-nav a:link {
            display: block;
            padding: 0.1em 1em;
        }

        ul.main-nav a:hover, ul.main-nav a.selected {
            background-color: #dcdcd8;
        }
        ul.main-nav li.break {
            margin-top: 1em;
        }

        .text {
            color: #666;
            font-size: 16px;
            line-height: 21px;
            padding: 0 15px;
            a:link, a:visited {
                border-bottom: 1px dotted #888;
            }
            a:hover, a:active {
                border-bottom: none;
                color: #D24242;
            }
        }

        footer {
            position: absolute;
            bottom: 30px;
        }
    }
    > header {
        grid-column: 4 / 13;
        grid-row: 1;

        #english-title {
            margin-top: 6px;
            margin-left: 2%;
            width: 90%;
        }
    }
    > main {
        grid-column: 4 / 11;
        grid-row: 1;
        padding-top: 7.5vw;
        padding-left: 10%;
        padding-right: 10%;
        position: relative;
        padding-bottom: 1em;
        section.footnotes {
            font-size: 16px;
            line-height: 21px;
            font-weight: 440;

            li { margin-left: 2em; }
        }

        p + p {
            text-indent: 30px;
        }

        dl {
            margin: 12px 0;
        }
        
        dd {
            margin-left: 1em;
        }
 
       table {
           td, th {
               padding-right: 0.5em;
           }
           th {
               font-weight: 500;
               text-align: left;
           }
           tr:first-child th {
               font-variant: all-small-caps;
               font-weight: 600;
               letter-spacing: 0.1em;
               text-align: center;
           }
       }

        figure {
            background-color: #fffff8;
            padding: 0.5rem;
            margin: 30px 0;

            figcaption {
                text-align: center;
                color: #666;
            }
        }
        
        .home article {
            margin-top: 14px;
            h1 {
                font-weight: 550;
            }
        }
        
        article > header h1 {
            text-align: center;
            font-size: 30px;
            line-height: 36px;
            font-weight: 500;
            padding-bottom: 30px;
        }
        
        article > footer {
            width: 50%;
            margin-left: 50%;
            margin-top: 1em;
            margin-bottom: 1em;
            font-style: italic;
            address {
                font-weight: 500;
                margin-left: -2em;
                font-style: normal;
            }
        }

        article > main {
            h1 {
                margin: 36px 0 12px;
                font-size: 24px;
                font-weight: 500;
            }
            h2 {
                margin: 24px 0 12px;
                font-size: 21px;
                font-weight: normal;
                text-transform: uppercase;
                letter-spacing: 0.03em;
                word-spacing: 0.3em;
                font-weight: 450;
            }
            h3 {
                margin-top: 24px;
                font-weight: normal;
                font-style: italic;
            }
        }
    }
    > footer {
        grid-column: 1 / 13;
        display: none;
    }
}

// general text styles
a:link, a:visited {
    border-bottom: 1px solid #ccb;
}
a:hover, a:active {
    border-bottom: none;
    color: #D24242;
}

sup {
    font-variant-position: super;
    -moz-font-feature-settings: "sups";
    -webkit-font-feature-settings: "sups";
    font-feature-settings: "sups";

}
sub {
    font-variant-position: sub;
    -moz-font-feature-settings: "subs";
    -webkit-font-feature-settings: "subs";
    font-feature-settings: "subs";
}

hr {
    border: none;
    padding-bottom: 1em;
    text-align: center;
    &::after {
        content: '\2042';
        font-size: 36px;
    }
}

blockquote {
    margin: 0 1em;
    font-size: 18px;
    font-weight: 420;
}

p {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

ol > li {
    margin: 0 1em;
}

ul > li {
    margin-left: 1em;
    margin-right: 1em;
    list-style-type: disc;
    list-style-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="10" height="10"><text x="2" y="12.5" style="font-size: 16px; fill: %23888;">&%23x2022;</text></svg>'); ;
}

a.footnote-ref:link, a.footnote-ref:visited {
    border: none;
}
html.sidenotes {
    main section.footnotes {
        li { position: absolute; }
        position: absolute;
        top: 0;
        left: 90%;
        width: 35%;
        hr { display: none; }
        .footnote-back { display: none; }
    }
}

@media (max-width: 1000px) {
    a.footnote-ref:link, a.footnote-ref:visited {
        text-decoration: underline;
    }
    html.sidenotes {
        main section.footnotes {
            li { position: static; }
            position: static;
            width: 100%;
            hr { display: block; }
            .footnote-back { display: inline; margin-left: 0.5em; border: none; color: #666; font-family: 'Lucida Grande', 'Arial Unicode MS', 'Zapf Dingbats'; }
        }
        body > main {
            grid-column: 4 / 13;
        }
    }
}
@media (max-width: 700px) {
    body {
        display: block;
        > nav {
            #logo { max-width: 300px; }
            > footer {
                position: static;
                padding: 30px;
            }
        }
    }
}

@media (max-width: 500px) {
    body {
        font-size: 18px;
        line-height: 21px;

        > main {
            padding-left: 5%;
            padding-right: 5%;
        }
    }

    blockquote {
        font-size: 16px;
    }
}


@media (prefers-color-scheme: dark) {
    html {
        background-color: #2b2f36;
        color: #eee;
    }
    body {
        > nav {
            background-color: #3a4048;
            #logo:hover {
                background-color: #2c3038;
            }
            .text {
                color: #bbb;
            }
            ul.main-nav a:hover, ul.main-nav a.selected {
                background-color: #2c3038;
            }
        }
    }
    a:link, a:visited {
        border-bottom: 1px solid #ccd;
    }
    a:hover, a:active { border-bottom: none; }
    .footnote-back { color: #ccd; }
}
